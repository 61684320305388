@use "../../../vars.scss" as *;

.FrameListItem,
.FrameListItem img {
  display: block;
  height: 100%;
}

.FrameListItem {
  padding-right: 0.75rem;
  position: relative;
}

.FrameListItem img {
  display: block;
  height: 100%;
  border-radius: 0.25rem;
  background-color: white;
  pointer-events: none;
}

.FrameListItem .Index {
  position: absolute;
  right: 1.25rem;
  bottom: 0.5rem;

  color: $bg1;
  text-decoration: none;
  background-color: $fg2;
  border-radius: 0.25rem;
  padding: 0.25rem;
}
