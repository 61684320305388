/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

@use "../../vars.scss" as *;

.ProjectListItem {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: $bg2;
  transition: background-color $transition;

  text-align: center;
  cursor: pointer;

  font-family: $font;
  font-size: 1rem;
  text-decoration: none;
  border: none;
}

.ProjectListItem:hover,
.ProjectListItem.focus-visible {
  background-color: $bg3;
}

.ProjectListItem .PreviewImageWrapper {
  height: 0;
  padding-top: 56.25%;
  position: relative;
  margin-bottom: 0.5rem;
}

.ProjectListItem .PreviewImageWrapper .PreviewImage {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ProjectListItem .PreviewImageWrapper img.PreviewImage {
  border-radius: 0.25rem;
  object-fit: cover;
}

.ProjectListItem .PreviewImageWrapper .PreviewImageSVGWrapper {
  @extend .PreviewImage;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ProjectListItem .PreviewImageWrapper .PreviewImageSVGWrapper svg {
  fill: $fg2;
  height: 75%;
}

.ProjectListItem .Title {
  color: $fg2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
