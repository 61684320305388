/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

$font: "Open Sans", sans-serif;

$bg1: #121212;
$bg2: #353535;
$bg3: #3e3e3e;
$bg4: #4d4d4d;

$fg1: rgba(255, 255, 255, 0.87);
$fg2: rgba(255, 255, 255, 0.6);
$fg3: rgba(255, 255, 255, 0.38);

$fgError: #d83451;

$transition: 0.2s;
