.ProjectSettingsWrapper {
  display: flex;
  flex-grow: 1;
  overflow: auto;
}

.ProjectSettings {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  margin: auto;
  width: 100%;
  max-width: 22rem;
}

.ProjectSettings > * {
  margin-bottom: 1rem;
}
.ProjectSettings > :last-child {
  margin-bottom: 0;
}

.ProjectSettings .Button {
  align-self: flex-end;
}
