@use "../../../vars.scss" as *;

.FrameList {
  margin: 0;
  list-style: none;

  height: 20%;
  display: flex;
  padding: 0.5rem 0.75rem;
  padding-right: 0;
  background-color: $bg2;
  overflow: auto;

  position: relative;
}

.FrameList.Loading {
  overflow: hidden;
}

.FrameListLoaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bg2;
}

.FrameListLoaderOverlay .Loader {
  border-color: $bg4;
}
