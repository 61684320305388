/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

@use "../vars.scss" as *;
@use "sass:color";

.Button {
  font-family: $font;
  font-size: 1rem;
  border: none;
  text-decoration: none;
  outline: none;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.25rem;
  background-color: $bg2;
  color: $fg2;
  padding: 0 0.75rem;
  border-radius: 0.25rem;

  text-transform: uppercase;
  cursor: pointer;
  transition: background-color $transition, opacity $transition,
    color $transition;
}

.Button:hover,
.Button.focus-visible {
  background-color: $bg3;
}

.Button:disabled {
  pointer-events: none;
  background-color: color.change($bg2, $alpha: 0.75);
  color: $fg3;
}
