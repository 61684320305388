/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

@use "../vars.scss" as *;
@use "sass:color";

.IconButton {
  border: none;
  padding: 0;
  outline: none;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;

  background-color: $bg2;
  transition: background-color $transition;
  cursor: pointer;
}

.IconButton svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: $fg2;
  transition: fill $transition;
}

.IconButton:hover,
.IconButton.focus-visible {
  background-color: $bg4;
}

.IconButton:disabled {
  pointer-events: none;
  background-color: color.change($bg2, $alpha: 0.75);
}
.IconButton:disabled svg {
  fill: $fg3;
}

.IconButton.Loading svg {
  display: none;
}
.IconButton.Loading .Loader {
  width: 1.75rem;
  height: 1.75rem;
  border-width: 0.25rem;
  border-color: $bg4;
}

.IconButton.Large {
  width: 3rem;
  height: 3rem;
}
.IconButton.Large svg {
  width: 2.25rem;
  height: 2.25rem;
}
