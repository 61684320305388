/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

@use "../vars.scss" as *;

.AppBar {
  display: flex;
  height: 3.25rem;
  align-items: center;
  flex-shrink: 0;
  background-color: $bg2;
  padding: 0 1rem;
}

.AppBar .Title {
  margin: 0;
  flex-grow: 1;
  color: $fg1;
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AppBar .IconButton + .IconButton {
  margin-left: 0.25rem;
}

.AppBar .IconButton + .Title,
.AppBar .Title + .IconButton {
  margin-left: 0.5rem;
}
