/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

@use "../vars.scss" as *;

.ErrorWrapper {
  display: flex;
  flex-grow: 1;
  overflow: auto;
}

.Error {
  margin: auto;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.Error svg {
  width: 12rem;
  height: 12rem;
  flex-shrink: 0;
  margin-right: 0.5rem;
  fill: $fg2;
}

.Error .ErrorInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Error .ErrorInfo .Text {
  margin-bottom: 1rem;
}

@media (max-width: 26.1875rem) {
  .Error {
    flex-direction: column;
    text-align: center;
  }
  .Error svg {
    margin-right: 0;
  }
  .Error .ErrorInfo {
    align-items: center;
  }
}
