/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

@use "../../../vars.scss" as *;

.Recorder {
  flex-grow: 1;
  height: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.Recorder .Preview {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.Recorder .Preview:focus {
  outline: none;
}

.Recorder .ButtonPanel {
  flex-grow: 1;
  max-width: 4.25rem;
  position: relative;
  display: flex;
  align-items: center;
}

.Recorder .ButtonPanelInner {
  position: absolute;
  right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Recorder .ButtonPanelInner > * {
  margin-bottom: 1rem;
}
.Recorder .ButtonPanelInner > :last-child {
  margin-bottom: 0;
}

.Recorder .HiddenFileInput {
  display: none;
}

.Recorder .LoaderOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bg1;
}

.CameraErrorWrapper {
  flex-grow: 1;
  display: flex;
  overflow: auto;
}

.CameraError {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
}

.CameraError svg {
  fill: $fgError;
  width: 5rem;
  height: 5rem;
}

.CameraError .Text {
  margin-bottom: 1rem;
}
