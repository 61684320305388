/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

@use "../vars.scss" as *;

.InputWrapper input {
  width: 100%;
  min-width: 0;
  border: none;
  font-family: $font;
  font-size: 1rem;

  height: 2.25rem;
  padding: 0 0.5rem;
  background-color: $bg2;
  color: $fg2;

  border-radius: 0.25rem 0.25rem 0.0625rem 0.0625rem;
  border-bottom: 0.125rem solid $fg3;

  transition: background-color $transition;
}

.InputWrapper input:focus {
  background-color: $bg3;
  outline: none;
}

.InputWrapper label {
  display: block;
  margin-bottom: 0.5rem;
  pointer-events: none;
}
