/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

@use "./vars.scss" as *;

html,
body {
  margin: 0;
  overflow: hidden;
  position: fixed;
}

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;

  background-color: $bg1;
  color: $fg2;

  font-family: $font;
  user-select: none;
}

.App * {
  box-sizing: border-box;
}

.App * {
  scrollbar-width: thin;
  scrollbar-color: $fg2 $bg4;
}
.App ::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}
.App ::-webkit-scrollbar-track {
  background-color: $bg4;
}
.App ::-webkit-scrollbar-thumb {
  background-color: $fg2;
}

.App ::-moz-focus-inner {
  border: 0;
}
.App * {
  -webkit-tap-highlight-color: transparent;
}
.App .focus-visible {
  outline: 0.125rem solid $fg1;
  outline-offset: 0.25rem;
  z-index: 1;
}
