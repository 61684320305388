/*
Stop Motion – a web app for creating stop motion videos.
Copyright (C) 2021 Gregor Parzefall

This program is free software: you can redistribute it and/or modify
it under the terms of the GNU Affero General Public License as published
by the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Affero General Public License for more details.

You should have received a copy of the GNU Affero General Public License
along with this program.  If not, see <https://www.gnu.org/licenses/>.
*/

@use "../../vars.scss" as *;

.ProjectListWrapper {
  overflow: auto;
}
.ProjectListWrapper.Loading {
  overflow: hidden;
}

.ProjectList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  padding: 1rem;
  gap: 1rem;
  margin: 0;
  list-style: none;
}

.ProjectListLoaderOverlay {
  position: absolute;
  top: 3.25rem;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bg1;
}
